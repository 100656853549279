#header {
  float: left;
  width: 100%;
  background-color: #0085d5;
  color: #ffffff;
  text-align: center;
  padding: 24px 0px 19px 0px;
  position: relative;
  text-align: center;
}

#header #headerInner {
  width: 76%;
  display: inline-block;
}

#header #logo {
  float: left;
}

#wrapperHeader {
  font-size: 25px;
  margin-top: 25px !important;
  color: #53565a;
  text-align: center;
  max-width: 90%;
  display: inline-block;
  margin-bottom: 25px !important;
}

.wrapper {
  width: 100vw;
  text-align: center;
}

#containerHeader {
  font-size: 20px;
  margin-top: 25px !important;
  color: #85888c;
  text-align: center;
  margin-bottom: 25px !important;
  max-width: 90%;
  color: #53565a;
  display: inline-block;
}

#seahorseBtn {
  padding: 1%;
  min-width: 15% !important;
  background-color: #0085d5;
  color: #ffffff;
  cursor: pointer;
  margin: 1%;
  font-size: 1.5em;
  min-height: 100px;
  display: inline-table;
  text-align: center;
}

#seahorseBtn:hover {
  background-color: #00426a;
}
